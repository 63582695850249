.found-title{
    font-size: large;
    font-weight: bold;
    margin-bottom: 0;
}
.feedback {
    text-align: center;
    margin: auto;
    max-width: 700px;
    color: red;
    font-size: large;
}
.found-title {
  margin-bottom: 0;
  font-size: large;
  font-weight: bold;
}

.feedback {
  text-align: center;
  color: red;
  max-width: 700px;
  margin: auto;
  font-size: large;
}
/*# sourceMappingURL=index.6fa66533.css.map */
